import React, { useState, useEffect } from 'react';

function Loader() {
  return (
    <div className="App">
      <div className="App-header">
        <img
          className="App-logo"
          src="./Searaider.jpg"
          alt="Searaider"
        />
        <p>
          Loading...
        </p>
      </div>
    </div>
  );
}

function Content() {
    return (
    <div className="content">
        <div className="side">
            <img className='logo' src="../Searaider.jpg" alt="Searaider" />
            <p className='support' align="center"><a href='https://ko-fi.com/giorgimakh' target='_blank'><img height='42' src='https://storage.ko-fi.com/cdn/brandasset/kofi_s_logo_nolabel.png' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a></p>
        </div>
        <div className="videos">
            <iframe className="video" src="https://www.youtube.com/embed/ibEhU8R1s80?si=1MYjtjk_4CEEyfkd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe className="video" src="https://www.youtube.com/embed/97l8ziYqPgY?si=Lp2Xu7k1DjYN1Bzi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe className="video" src="https://www.youtube.com/embed/QBILVFiGn10?si=znezFsRmnSXiy3DX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe className="video" src="https://www.youtube.com/embed/PC4hI4PbvEg?si=jVPGz-HW5zD1YhNK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
    )
}


export default function Index(){
    const [isLoading, setIsLoading] = useState(true);

    // Simulate a delay to mimic data loading
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }, []);
    return (
        <div>
        { isLoading ? <Loader/> : <Content/>}
    </div>
    )
}